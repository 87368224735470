export default {
  home: 'الرئيسية',
  Services: 'الخدمات',
  About: 'من نحن',
  request: 'اطلب خدمة الترجمة الآن',
  Blogs: 'المدونات',
  Blog: 'المدونة',
  All:'الكل',
  FeaturedBlogs: ' المدونات المميزة',
  Contact_Us: 'تواصل معنا',
  Sign_in: 'تسجيل الدخول',
  home_text_1: 'كل خدمات الترجمة التي تبحث عنها في مكان واحد',
  home_text_2: 'أسرع عرض أسعار خلال 30 ثانيـة فقط ، وبـأسعـار  أقل  بنســبة ٧٠٪   جودة عالية - سرعة في التسليم - أفضل فريق لغوي معتمد',
  home_text_3: 'اطلب عرض أسعار',
  services_text_1: 'تعرف على خدماتنا',
  services_text_2: 'جميع الخدمات',
  services_title_1: 'الترجمة الاحترافية',
  services_title_2: 'التوطين',
  services_title_3: 'التفريغ الصوتي',
  services_title_4: 'ترجمة المواقع',
  services_title_5: 'التحرير والتدقيق المهني',
  services_title_6: 'النشر المكتبي (DTP)',
  services_description_1: 'تخطى حاجز اللغات المختلفة وأوصل المعنى الصحيح لعملائك بالكلمات الملائمة لثقافتهم. نوفر لك خدمات الترجمة الاحترافية التي تتخطى حاجز الكلمات واللغات.',
  services_description_2: 'نترجم وثائقك ومستنداتك ترجمة تناسب ثقافة عملائك ولغتهم العامية وليست ترجمة حرفية للمصطلحات ليصل المعنى المراد لعملائك في جميع أنحاء العالم',
  services_description_3: 'لاتحمل هم ترجمة مقاطع الفيديو والرسوم المتحركة والصوتيات وبرامج التعليم الإلكتروني والتعليق الصوتي بعد الآن مع خدمة التفريغ الصوتي من ترجم',
  services_description_4: 'نقوم بترجمة موقعك الإلكتروني حتى توفر تجربة رائعة لعملائك، فنعمل على تحسين ما به من محتوى ورسومات ومفردات بحيث تلائم الأسواق العالمية.',
  services_description_5: 'احصل على مستندات بأعلى درجات الدقة والجودة من حيث صحة المصطلحات وقواعد الإملاء والوضوح وسلاسة القراءة بأكثر من 120 لغة.',
  services_description_6: 'حافظ على شكل مستنداتك متعددة اللغات لغرض الطباعة لتكون ذات تصميم رائع ملائم لثقافة البلد الذي تستهدفه .',
  know_more: 'تعرف أكثر',
  why_us_text_1: 'لماذا تختار ترجمة مستنداتك مع ترجم؟',
  why_us_text_2: 'أسرع عرض أسعار عبر Ai في 30 ثانية فقط. ',
  why_us_text_3: 'سعر تنافسي على جميع اللغات ، أقل بنسبة 70٪ من اللغات الأخرى.',
  why_us_text_4: 'أكثر من 120 لغويًا لديهم خبرات وقدرات في جميع المجالات.',
  why_us_text_5: '3 خطوات لعملية ضمان الجودة.',
  why_us_text_6: ' توفير الوقت حيث نقدم خدمة النافذة الواحدة (إنشاء المحتوى - الترجمة - النشر المكتبي)',
  services_steps_text_1: 'خطوات بسيطة لعملية الترجمة',
  services_steps_text_2: 'استلام المستند باللغة الأساسية',
  services_steps_text_3: 'قم برفع المستند باللغة الأساسية ونراعي هنا اتفاقية عدم الإفصاح عن السرية',
  services_steps_text_4: 'تقديم أسرع عرض أسعار مجاني',
  services_steps_text_5: 'يظهر لك سعراً تقديرياً في 30 ثانية فقط عن طريق الـ Ai',
  services_steps_text_6: 'بدء عملية الترجمة',
  services_steps_text_7: 'نبدأ في خطوات الترجمة إلى أن نصل الى آخر مرحلة من مراحل الترجمة وهي المراجعة والتدقيق ',
  services_steps_text_8: 'تسليم ترجمة متميزة ودقيقة',
  services_steps_text_9: 'استلم المستند النهائي بأعلى جودة وبالصيغة التي تريدها',
  services_steps_text_10: 'اطلب ترجمة الآن',
  partners_text_1: 'شركاؤنا',
  footer_text_1: 'سياسة الخصوصية و شروط الخدمة',
  footer_text_2: ' جميع الحقوق محفوظة لموقع ترجم © ٢٠٢٢',
  Contact_Us_text_1: 'مرحباً بك، يسعدنا التواصل معك!',
  Contact_Us_text_2: " اترك لنا رسالة عن مشروعك أو أرسل استفسارك وسنقوم بالرد عليك في أسرع وقت ممكن",
  Contact_Us_text_3: "الاسم بالكامل",
  Contact_Us_text_4: "البريد الإلكتروني",
  Contact_Us_text_5: 'رقم الهاتف',
  Contact_Us_text_6: 'اترك رسالتك هنا',
  Contact_Us_text_7: 'إرسال',
  Contact_Us_text_8:'+971565018597',
  Contact_Us_text_9: 'البريد الالكتروني ',
  Contact_Us_text_10: ' دبي - القرهود - مركز الأعمال',
  Contact_Us_text_11: 'العنوان',
  Contact_Us_text_12: 'الهاتف',
  Contact_Us_text_13: '+97145488949',
  About_text_1: 'من نحن ',
  About_text_2: 'شركة ترجم هي شركة تابعة لشركة فيرست كابيتال القابضة للاستثمار,أول موقع لخدمات الترجمة في الإمارات العربية المتحدة يمنح للمستخدم بالحصول على عرض أسعار فوريه عن طريق الذكاء الاصطناعي وفي أقل من 30 ثانية, الجودة العالية هي ما نسعى إليه لضمان ترجمة مستنداتك بدقة ، نقوم  بالترجمة على 3 خطوات : معرفة من هو الجمهور المستهدف للترجمة، وماهي المصطلحات التي يستخدمها، وخطوة التدقيق اللغوي والمراجعة, نقدم أفضل نظام للجودة بمعايير ISO، أقوى نظام تدقيق لغوي للتأكد من خلو المستند المترجم من الأخطاء وتسليمه في الوقت المحدد, يستخدم فريق Trjim أحدث وأقوى إجراءات ضمان جودة الترجمة 3TEP (الترجمة - التعديل - التدقيق)',
  login_text_1: 'تسجيل دخول',
  login_text_2: 'مرحباً بعودتك! نتمنى أن تكون سعيدا بخدمتنا …',
  login_text_3: 'تسجيل الدخول باستخدام',
  login_text_4: 'مرحباً بك في',
  login_text_5: 'ترجم',
  login_text_6: 'انطلاقًا من التزامها بتقديم خدمات ترجمة احترافية ، تمتلك تراجيم فريقًا مختارًا من اللغويين بدرجة عالية من الشهادات العالمية للجودة.',
  login_text_7: 'البريد الإلكتروني',
  login_text_8: 'كلمة المرور',
  login_text_9: 'نسيت كلمة المرور ',
  login_text_10: 'إنشاء حساب جديد',
  login_text_11: 'تسجيل دخول',
  register_text_1: 'إنشاء حساب جديد',
  register_text_2: 'ابدأ الان وتمتع بكل خدمات الترجمة …',
  register_text_3: 'تسجيل الدخول باستخدام',
  register_text_4: 'الاسم بالكامل',
  register_text_5: ' إنشاء حساب',
  register_text_6: 'تسجيل دخول ',
  register_text_7: ' لدي حساب ',
  reset_pass_text_1: 'نسيت كلمة المرور',
  reset_pass_text_2: 'أدخل عنوان البريد الإلكتروني الذي استخدمته عند التسجيل لأول مرة.  ',
  reset_pass_text_3: 'سنرسل لك تعليمات لإعادة تعيين كلمة المرور الخاصة بك.',
  reset_pass_text_4: 'إرسال رمز إعادة التعيين',
  reset_pass_text_5: 'العودة إلى',
  reset_pass_text_6: 'تسجيل دخول',
  order_text_1: 'إختر الخدمة',
  order_text_2: 'قم بإختيار الخدمة التي تريدها من القائمة',
  order_text_3: 'إختر لغة الترجمة',
  order_text_4: 'قم بإختيار لغة الملف الذي تريد ترجمته',
  order_text_5: 'واللغة التي تريد الترجمة إليها',
  order_text_6: 'قم بسحب الملف الذي تريد ترجمته من هنا',
  order_text_7: 'قم بسحب الملف التي تريد ترجمته من هنا',
  order_text_8: 'إختر الخدمة التي تحتاجها',
  order_text_9: 'إختر لغةالترجمة',
  order_text_10: 'إختر لغة الملف الذي تريد ترجمته ',
  order_text_11: 'إختر اللغة التي تريد الترجمة إليها',
  order_text_12: 'التخصص',
  order_text_13: 'الملف المراد ترجمته',
  order_text_14: 'يجب أن تكون صيغة الملف',
  order_text_15: 'اذا كانت صيغة الملف لديك غير مدعومة لدينا',
  order_text_16: 'إرسل طلبك من هنا',
  order_text_17: 'إختر الدولة ',
  order_text_18: 'الإسم الأول',
  order_text_19: 'الإسم الأخير',
  order_text_20: 'العنوان',
  order_text_21: 'تفاصيل الطلب',
  order_text_23: 'عدد الكلمات',
  order_text_24: 'عدد صفحات الملف',
  order_text_25: 'التكلفة',
  order_text_26: 'إجمالي الطلب',
  order_text_27: 'جاري التحميل من فضلك انتظر...',
  order_text_28: 'شكرآ على طلبك تم بنجاح',
  order_text_29: 'تم إستقبال طلب الترجمة بنجاح',
  order_text_30: 'تابع طلبك من قائمة الطلبات الخاصة بك وسيتم رفع الملف المترجم في أقرب وقت',
  order_text_31: 'سيتم إرسال بريد اليك عندما تتم الترجمة',
  order_text_32: 'الرئيسية',
  order_text_33: 'بيانات الطلب',
  user_admin_text_1: 'إجمالي الطلبات',
  user_admin_text_2: 'إجمالي المدفوع',
  user_admin_text_3: 'طلبات تم ترجمتها',
  user_admin_text_4: 'طلبات قيد الترجمة',
  user_admin_text_5: 'طلبات',
  user_admin_text_6: 'رقم الطلب',
  user_admin_text_7: 'تاريخ الطلب',
  user_admin_text_8: 'اللغة',
  user_admin_text_9: 'قيمة الطلب',
  user_admin_text_10: 'حالة الطلب',
  user_admin_text_11: 'تحميل الطلب',
  user_admin_text_12:'طريقة الدفع',
  user_admin_text_13:'الكلمات',
  user_admin_text_14:'الملف',
  user_admin_text_15:'التخصص',
  user_seting_text_1: 'الإسم بالكامل ',
  user_seting_text_2: 'البريد الإلكتروني',
  user_seting_text_3: 'ساعدنا بالتواصل معك',
  user_seting_text_4: ' رقم جوال ',
  user_seting_text_5: ' عرفنا بهويتك',
  user_seting_text_6: 'حفظ التعديلات',
  user_seting_text_7: 'شركة او مؤسسة',
  user_seting_text_8: 'فرد',
  user_seting_text_9:'المعلومات الشخصية ',

  user_menu_text_1: 'الطلبات',
  user_menu_text_2: 'إعدادات الحساب',
  user_menu_text_3: 'تسجيل خروج',
  backend_next: 'التالي',
  backend_previus: 'السابق',
  valid_email: 'يجب أن يكون محتوي الإميل و كلمة المرور صحيحة',
  field_require:'هذا الحقل مطلوب',
  service_require:'الخدمة مطلوبة',
  choose_sercice:'إختر الخدمة',
  email_fail:'البريد الإلكتروني أو كلمة المرور غير صحيحة',
  payment:'دفع',
  dashboard:'لوحة التحكم',
  whatsapp_text_1:'مرحباً بك في ترجم،تواصل معنا',
  whatsapp_text_2:'إرسل لنا عبر الواتساب.',
  whatsapp_text_3:'تواصل معي',
  whatsapp_text_4:'فريق الدعم',
  noResult:'لا نتائج',
  within:'فى خلال',
  workingDays:'ايام عمل'


};