<template>
  <!-- Site footer -->
  <div id="footer">
      <v-row>
        <v-col cols="12" md="12">
          <div class="tr-footer-content">
            <div class="tr-footer-logo">
              <router-link to="/" tag="span" style="cursor: pointer">
                <v-img :src="require('@/assets/images/logo.svg')" width="100" />
              </router-link>
            </div>
            <v-spacer></v-spacer>

            <div class="tr-footer-menu"  v-for="(menu, key) in menus" :key="key">
              <ul class="tr-footer-menu-holder">
                <li>
                  <router-link :to="menu.route" class="menu-nav-item">
                    {{ menu.name }}
                  </router-link>
                </li>
              </ul>
            </div>
            <v-spacer></v-spacer>
            <div class="tr-footer-social">
              <v-btn target="_blank" href="https://www.facebook.com/trjimnow" class="mx-2" icon>
                <v-img
                  :src="require('@/assets/images/utility/facebook.svg')"
                ></v-img>
              </v-btn>
              <v-btn target="_blank" href="https://twitter.com/trjimnow" class="mx-2" icon>
                <v-img
                  :src="require('@/assets/images/utility/twitter.png')"
                ></v-img>
              </v-btn>
              <v-btn target="_blank" href="https://www.instagram.com/trjimnow" class="mx-2" icon>
                <v-img
                  :src="require('@/assets/images/utility/insta.png')"
                ></v-img>
              </v-btn>
              <v-btn target="_blank" href="https://www.linkedin.com/company/trjimsite" class="mx-2" icon>
                <v-img
                  :src="require('@/assets/images/utility/linkedin.svg')"
                ></v-img>
              </v-btn>
            </div>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="3" />
        <v-col cols="12" md="3" />
        <v-col cols="12" md="3" />
        <v-col cols="12" md="3">
          <div class="tr-bootom-footer payments">
            <v-img
              class="tr-bottom-footer-img"
              :src="require('@/assets/images/utility/visa.svg')"
              width="50px"
            ></v-img>
            <v-img
              class="tr-bottom-footer-img"
              :src="require('@/assets/images/utility/master.svg')"
              width="50px"
            ></v-img>
          </div>
        </v-col>
      </v-row>
      <v-row class="border-top">
        <v-col cols="12" md="12">
          <div class="tr-footer-content">
            <v-spacer></v-spacer>
            <div class="tr-footer-menu">
              <p> <router-link :to="{name: 'terms-condition'}"> {{$t('footer_text_1')}} </router-link> </p>
            </div>
            <v-spacer></v-spacer>
            <div class="tr-footer-social">
              <p>{{$t('footer_text_2')}}</p>

            </div>
          </div>
        </v-col>
      </v-row>
  </div>
</template>

<script>
let menus_data = [];
if (localStorage.locale === "ar") {
     menus_data = [
      { name: "الرئيسية", route: "/" },
      { name: "الخدمات", route: "/services" },
      { name: "من نحن", route: "/about" },
     // { name: "المدونات", route: "/blogs" },
      { name: "تواصل معنا", route: "/contact" },
      { name: "اطلب خدمة الترجمة الآن", route: "/order" },
    ];
    } else {
     menus_data = [
      { name: "Home", route: "/" },
      { name: "Services", route: "/services" },
      { name: "About", route: "/about" },
     // { name: "Blogs", route: "/blogs" },
      { name: "Contact Us", route: "/contact" },
      { name: "Get instant quote", route: "/order" },
    ];
   }
export default {
  data: () => ({
    icons: ["mdi-facebook", "mdi-twitter", "mdi-linkedin", "mdi-instagram"],
    menus: menus_data ,
  })
};
</script>

<style scopped lang="scss">
#footer {
  background-color: #f9f9fa;
  padding: 3rem 3rem 0;
}

.tr-footer-menu-holder {
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  flex-direction: row;
}

.tr-footer-menu-holder li {
  list-style-type: none;
  a {
    color: #202020;
  }
}

.tr-footer-content {
  display: flex;
  flex-wrap: nowrap;
  align-content: center;
  flex-direction: row;
  justify-content: space-between;
}

.tr-bottom-footer-img {
  display: block !important;
  margin: 0.8rem;
  float: left;
}

.tr-footer-menu p a {
  color: #202020;
  text-decoration: underline;
}

.tr-footer-social p {
  font-weight: 200;
}

.border-top {
  border-top: 1px solid #bfbfbf;
}

@media screen and (max-width: 999px) {
  .tr-footer-content,
  .tr-footer-menu-holder {
    display: block;
    margin: 1rem;
  }
}

.menu-nav-item {
  color: #808080 !important;
}

.menu-nav-item.router-link-exact-active.router-link-active {
  color: #202020 !important;
}

.tr-footer-social .v-image {
  filter: grayscale(1);
  max-width: 26px;
  &:hover {
    filter: grayscale(0);
  }
}
</style>
