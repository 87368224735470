import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n } from '@/plugins/i18n'
import VueMeta from 'vue-meta'
import VueSocialChat from 'vue-social-chat'
import Hotjar from 'vue-hotjar'
import Default from '@core/layouts/default-layout/DefaultLayout.vue'
import Blank from '@core/layouts/blank-layout/BlankLayout.vue'
import Dashboard from '@core/layouts/user-admin-layout/UserAdminLayout.vue'
import axiosSetup from './lib/axios-instances'
import VueGtm from '@gtm-support/vue2-gtm';

import vuetify from './plugins/vuetify'
// Plugins
import './plugins/vue-facebook-pixel'
import VueSchemaMicrodata from 'vue-schema-microdata'
import VueJsonLD from 'vue-jsonld'
import '@/plugins/vue-composition-api'


Vue.use(VueJsonLD)

Vue.use(VueSchemaMicrodata)

Vue.use(VueSocialChat)
Vue.component('default-layout', Default)
Vue.component('blank-layout', Blank)
Vue.component('dashboard-layout', Dashboard)

const prerenderEventName = 'prerender-event';

Vue.config.productionTip = false

Vue.use(VueMeta, {
  refreshOnceOnNavigation: true
})

import './scss/main.scss';

import 'material-design-icons-iconfont/dist/material-design-icons.css'

axiosSetup()
// register axios apis instances
// Vue.use(axiosSetup)
Vue.filter("formatDate", function (value) {
  if (value) {
    var myDate = new Date(value);
    var year = myDate.getFullYear();
    var mes = myDate.getMonth() + 1;
    var dia = myDate.getDate();
    var filteredDate =  dia + "-" + mes + "-" + year ;
    return filteredDate;
  }
});
new Vue({
  router,
  store,
  render: h => h(App),
  i18n,
  vuetify,
  mounted() {
    this.$nextTick(() => {
      document.dispatchEvent(new Event(prerenderEventName));
    });
  }
}).$mount('#app')

Vue.use(Hotjar, {
  id: '2267319',
  isProduction: true,
  snippetVersion: 6
})

Vue.use(VueGtm, {
    id: "GTM-TZBVSWZ", // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
    defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
    compatibility: true, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
    nonce: "2726c7f26c", // Will add `nonce` to the script tag
    enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
    debug: true, // Whether or not display console logs debugs (optional)
    loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
    vueRouter: router, // Pass the router instance to automatically sync with router (optional)
    // ignoredViews: ["homepage"], // Don't trigger events for specified router names (case insensitive) (optional)
    trackOnNextTick: true, // Whether or not call trackView in Vue.nextTi
})

// Vue.use(googletagmanager, {
//   config: {id: "AW-398797295" }
//   // async : {srs: "https://www.googletagmanager.com/gtag/js?id=AW-398797295"}
// });

// aws cloudfront create-invalidation --distribution-id YOUR_DISTRIBUTION_ID --paths '/*' --profile YOUR_PROFILE >/dev/null
