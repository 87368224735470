export default {
    home: 'Home',
    Services: 'Services',
    About: 'About',
    request: ' Get instant quote',
    Blogs: 'Blogs',
    Blog: 'Blog',
    All:'All',
    FeaturedBlogs: 'Featured Blogs',
    Contact_Us: 'Contact Us',
    Sign_in: 'Sign in',
    home_text_1: 'All translation services you are looking for in one place',
    home_text_2: 'Know your translation price in 30 seconds with Ai quotation without human interaction.Competitive prices that Save 70% of translation cost High quality - Fast delivery - Best certified linguist team',
    home_text_3:'Get instant quote',
    services_text_1:'Our services',
    services_text_2:'All services',
    services_title_1:'Translation',
    services_title_2:'Localization',
    services_title_3:'Transcription',
    services_title_4:'Website Translation',
    services_title_5:'Professional Editing and Proofreading',
    services_title_6:'Desktop Publishing Service (DTP)',
    services_description_1:'Crossing the barriers of words and languages around the world.We provide you with professional translation services that transcend the barrier of words and languages.',
    services_description_2:'We translate your documents in a translation that suits the culture and slang of your customers, not a literal translation of terms to reach the intended meaning for your customers all over the world    ',
    services_description_3:'Translating video clips, animations, audio, e-learning programs, and voiceovers with transcription service with Trjim.com',
    services_description_4:'We translate your website to provide a great experience for your customers, and we work on improving its content, graphics, and vocabulary to suit global markets.',
    services_description_5:'Get documents of the highest accuracy and quality in terms of terminology, spelling, clarity, and readability in more than 120 languages.',
    services_description_6:'Keep your multilingual documents shaped for printing in a great design that matches the culture of your target country.',
    know_more:'know more',
    why_us_text_1:'Why Translate Your Documents with Trjim?',
    why_us_text_2:'Fastest quotation through Ai in 30 seconds only.',
    why_us_text_3:'Competitive prices for all languages, 70% less than others.',
    why_us_text_4:'More than 120 linguists have expertise and capabilities in all fields.',
    why_us_text_5:'3 steps of the quality assurance process (3TEP).',
    why_us_text_6:'Time-saving, as we offer a single-window service (Content Creation-Translation-DTP)',
    services_steps_text_1:'Simple steps for the translation process',
    services_steps_text_2:'Uploading the document in the primary language',
    services_steps_text_3:'Upload the document in the main language and we observe the non-disclosure agreement here',
    services_steps_text_4:'Providing the fastest quotation for free',
    services_steps_text_5:'Shows you an estimated price in just 30 seconds via the AI',
    services_steps_text_6:'Starting the translation process',
    services_steps_text_7:'We start with the translation steps until we reach the last stage of translation, which is review and proofreading',
    services_steps_text_8:'Receive an excellent and accurate translation',
    services_steps_text_9:'Receive the final document in the form that suits your needs',
    services_steps_text_10:'Order Translation',
    partners_text_1:'Our Clients',
    footer_text_1:' Privacy Policy and Terms of Service',
    footer_text_2:'All rights reserved Trjim © 2022 ',
    Contact_Us_text_1:'Get in touch!',
    Contact_Us_text_2:"Drop us a message about your project or send your query and we'll contact you as soon as possible!",
    Contact_Us_text_3:"Full Name",
    Contact_Us_text_4:"Email",
    Contact_Us_text_5:'Phone',
    Contact_Us_text_6:'Message',
    Contact_Us_text_7:'send',
    Contact_Us_text_8:'+971565018597',
    Contact_Us_text_9:'Email',
    Contact_Us_text_10:'Dubai - Al Garhoud - Al Garhoud Business Center',
    Contact_Us_text_11:'Address',
    Contact_Us_text_12:'Phone',
    Contact_Us_text_13: '+97145488949',
    About_text_1: 'About',
    About_text_2: "Trjim.com is a subsidiary from First Capital investment holding company. The first UAE based Translation services website that allows users to get AI instant Quotation, Almost in less than 30 seconds, and provides a seamless online experience to get files translated. Quality is our main concern To ensure that your message has been accurately translated, we apply our 3-step process: briefing on your targeted audience, terminology, and proofreading step. Our quality management system applies ISO criteria. Hence, we use industry best practices to make sure that translated document is error-free and delivered on time. Moreover, Trjim team uses the latest translation memory tools, AI quotation in 30 seconds only, and no human interaction.",
    login_text_1:'Sign in',
    login_text_2:'Welcome back! We hope you are happy with our services',
    login_text_3:'Log in with',
    login_text_4:'Welcome in ',
    login_text_5:'Trjim',
    login_text_6:'Competitive prices that Save 70% of translation cost High quality - Fast delivery - Best certified linguist team',
    login_text_7:'Email:',
    login_text_8:'Password:',
    login_text_9:'Forget password',
    login_text_10:'Create account',
    login_text_11:'Sign in',
    register_text_1:'Create your account',
    register_text_2:'Start Now, and enjoy all translation services',
    register_text_3:'Sign up with',
    register_text_4:'Full Name:',
    register_text_5:'Sign up',
    register_text_6:'sign in',
    register_text_7:'I have already an account',
    reset_pass_text_1:'Reset Your Password',
    reset_pass_text_2:'Please provide the email you used when you signed up for your Trjim account',
    reset_pass_text_3:"We'll send you an email to reset your password",
    reset_pass_text_4:'Send Reset code',
    reset_pass_text_5:'Back to',
    reset_pass_text_6:'sign in',

    order_text_1:'Select Service',
    order_text_2:'Select service you need from the list',
    order_text_3:'اختار اللغات التي ستترجم',
    order_text_4:'قم باختيار لغه الملف الذي تريد ترجمته',
    order_text_5:'واللغة التي تريد الترجمه اليها',
    order_text_6:'قم بسحب الملف الذي تريد ترجمته من هنا',
    order_text_7:'قم بسحب الملف التي تريد ترجمته من هنا',
    order_text_8:'Select service you need',
    order_text_9:'Choose Language',
    order_text_10:'Source file language:',
    order_text_11:'Target Language:',
    order_text_12:'Field:',
    order_text_13:'Upload File',
    order_text_14:'The file format must be',
    order_text_15:'If your file format is not supported by us',
    order_text_16:'send your request here',
    order_text_17:'Country',
    order_text_18:'First Name',
    order_text_19:'Last Name',
    order_text_20:'Address',
    order_text_21:'Request details',
    order_text_23:'Word Count',
    order_text_24:'Pages Count',
    order_text_25:'Cost ',
    order_text_26:'Total Price ',
    order_text_27:'جاري التحميل من فضلك انتظر...',
    order_text_28:'شكرا علي طلبك تم بنجاح',
    order_text_29:'تم استقبال طلب الترجمة بنجاح',
    order_text_30:'تابع طلبك من قائمة الطلبات الخاصه بك وسيتم رفع الملف المترجم في اقرب وقت',
    order_text_31:'سيتم ارسال بريد الكترونى اليك عندما تتم الترجمة',
    order_text_32:'الرئيسية',
    order_text_33:'Order Data',
    user_admin_text_1:'Total Requests',
    user_admin_text_2:'Total Payments',
    user_admin_text_3:'Done Requests',
    user_admin_text_4:'Pending',
    user_admin_text_5:'All Requests',
    user_admin_text_6:'Request No.',
    user_admin_text_7:'Request Date',
    user_admin_text_8:'Language',
    user_admin_text_9:'Price',
    user_admin_text_10:'Status',
    user_admin_text_11:'Download Translated File',
    user_admin_text_12:'Payment Method',
    user_admin_text_13:'Words',
    user_admin_text_14:'Files',
    user_admin_text_15:'Field',
    user_seting_text_1:'Full Name',
    user_seting_text_2:'Email',
    user_seting_text_3:'Help us contact you',
    user_seting_text_4:'Phone',
    user_seting_text_5:'Who are you?',
    user_seting_text_6:'Save Edits',
    user_seting_text_7:'Company',
    user_seting_text_8:'Individual ',
    user_seting_text_9:'Personal Information ',

    user_menu_text_1:'Orders',
    user_menu_text_2:'account settings',
    user_menu_text_3:'Log out',
    backend_next: 'Next',
    backend_previus: 'Previous',
    valid_email: 'The Email field and password must be a valid',
    field_require:'this field is required',
    service_require:'service is required',
    choose_sercice:'select service',
    email_fail:'please enter a valid username and password',
    payment:'pay',
    dashboard:'Dashboard',
    whatsapp_text_1:'Welcome to Trjim, we are here for you',
    whatsapp_text_2:'Send us now your Query، Whatsapp.',
    whatsapp_text_3:'Contact us',
    whatsapp_text_4:'Support Team',
    noResult:'No Result',
    within:'Within ',
    workingDays:' Working days'
}