<template>
  <component :is="layout">
    <v-slide-x-transition mode="out-in">
      <router-view />
    </v-slide-x-transition>
  </component>
</template>

<script>
const defaultLayout = 'default';
export default {
  computed: {
    layout() {
      return (this.$route.meta.layout || defaultLayout) + '-layout';
    },
  },
  mounted() {
    const html = document.documentElement;
    if (localStorage.locale === 'ar') {
      html.setAttribute('lang', 'ar');
      html.setAttribute('dir', 'rtl');
    } else {
      html.setAttribute('lang', 'en');
      html.setAttribute('dir', 'ltr');
    }

    if (!localStorage.locale) {
      localStorage.setItem('locale', 'ar');
      this.$router.go();
    }
  },
};
</script>

<style lang="scss">
// .v-application--wrap {
//   min-height: 10vh !important;
// }
// #app {
//   font-family: Avenir, Helvetica, Arial, sans-serif;
//   -webkit-font-smoothing: antialiased;
//   -moz-osx-font-smoothing: grayscale;
//   text-align: center;
//   color: #2c3e50;
// }

.v-application .primary {
  background-color: #6121d6 !important;
  border-color: #6121d6 !important;
}

.v-snack__content {
  font-size: 1.5rem !important;
}
</style>
