import Vue from 'vue';
import Vuex from 'vuex';
import login from './auth/login';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    locale: '',
    serviceId: '',
  },
  getters: {
    getServiceId(state) {
      return state.serviceId;
    },
  },
  mutations: {
    SET_LOCALE(state, locale) {
      state.locale = locale;
    },
    setServiceId(state, data) {
      state.serviceId = data;
    },
  },
  actions: {},
  modules: {
    login,
  },
  strict: process.env.DEV,
});
