<template>
  <v-app>
    <main class="main">
      <nav-bar />
      <slot />
      <tr-footer />
    </main>
  </v-app>
</template>

<script>
import TrFooter from '../../../components/layouts/Footer.vue';
import NavBar from '../../../components/layouts/NavBar.vue';
export default {
  components: { NavBar, TrFooter },
};
</script>

<style>
.main {
  min-height: 100%;
  background: #fcfcfc;
}
.maiDiv {
  min-height: 100%;
}
</style>