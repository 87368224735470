<template>
  <v-app>
    <v-main>
    
      <nav-bar />
      <main class="main">
        <v-row class="mx-0 mt-5">
          <v-col xl="2" md="3" class="d-none d-md-block">
        <vertical-nav />
      </v-col>
          <v-col xl="10" md="9" cols="12">
            <slot />
          </v-col>
        
        </v-row>
      
      </main>
    </v-main>
  </v-app>
</template>

<script>
import TrFooter from '../../../components/layouts/Footer.vue';
import NavBar from "../../../components/layouts/NavBar.vue";
import VerticalNav from '../../components/VerticalNav.vue';
export default {
  // eslint-disable-next-line vue/no-unused-components
  components: { NavBar, TrFooter, VerticalNav },
};
</script>,
    VerticalNav

<style>
.main {
  min-height: 100%;
}
.v-main__wrap{
background: #FCFCFC;
}
</style>