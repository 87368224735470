<template>
 
    <!-- <v-app-bar app>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer></v-spacer>
    </v-app-bar> -->
  <div class="conOfSideNav">
      <v-layout column align-center>
        <v-flex class="mt-5">
          <v-avatar size="96" color="deep-purple" v-if="!$store.state.login.userInfo.avatar">
            <span class="title white--text">{{ initialsName($store.state.login.userInfo.name) }}</span>
          </v-avatar>
          <v-avatar size="96" color="deep-purple" v-else>
            <img :src="$store.state.login.userInfo.avatar" :alt="initialsName($store.state.login.userInfo.name)" />
          </v-avatar>
          <p class="subheading mt-1 text-center">{{  $store.state.login.userInfo.name }}</p>
        </v-flex>
      </v-layout>
      <v-list flat>
        <v-list-item
          router
          to="/tr-admin/user-dashboard"
          active-class="border"
        >
          <v-list-item-action>
            <v-icon>text_snippet</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{$t('user_menu_text_1')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          router
          to="/tr-admin/user-settings"
          active-class="border"
        >
          <v-list-item-action>
            <v-icon>settings</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{$t('user_menu_text_2')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          active-class="border"
          @click="logout"
        >
          <v-list-item-action>
            <v-icon>logout</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>{{$t('user_menu_text_3')}}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
   
    </div>
</template>
<script>
export default {
  data: () => ({
    drawer: true,
    links: [
      { icon: 'dashboard', text: 'الطلبات', route: '/tr-admin/user-dashboard' },
      { icon: 'settings', text: 'اعدادات الحساب', route: '/tr-admin/user-settings' },
      { icon: 'logout', text: 'تسجيل خروج', route: '#', action: 'logout' }
    ]

  }),
  methods: {
    initialsName(name) {
      return name.match(/(^\S\S?|\b\S)?/g).join("").match(/(^\S|\S$)?/g).join("").toUpperCase()
    },
    logout() {
      this.$store.dispatch('login/logout')
    }
  }
}
</script>
<style scoped>
.border {
  border-left: 4px solid #6214df;
}
 .v-list .v-list-item--active .v-icon{
color: #6214df;
}
.v-list .v-list-item--active {
    background: linear-gradient(90deg, rgb(156 101 255 / 13%) 0%, rgb(97 33 214 / 0%) 50%);
}
.conOfSideNav{
  border: 2px solid #F2F2F2;
  height: 500px;
    background: #fff;
    border-radius: 12px;
    }
.conOfSideNav .v-list{
  border: none;
}
</style>
