import Vue from "vue";
import VueRouter from "vue-router";
import userAdmin from "./routes/userAdmin";
import auth from "./routes/authentication";
import store from "../store/index";

Vue.use(VueRouter);

const routes = [
  ...userAdmin,
  ...auth,
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue")
  },
  {
    path: "/services",
    name: "services",
    component: () => import("@/views/services/Services.vue")
  },
  {
    path: "/services/:id",
    name: "show-service",
    props: true,
    component: () => import("@/views/services/ShowService.vue")
  },
  {
    path: "/blogs",
    name: "blogs",
    component: () => import("@/views/blogs/Blogs.vue")
  },
  {
    path: "/blogs/:id",
    name: "show-blog",
    props: true,
    component: () => import("@/views/blogs/ShowBlog.vue")
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/About.vue")
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("@/views/ContactUs.vue")
  },
  {
    path: "/services/:id",
    name: "show-service",
    meta: {
      requiresAuth: true
    },
    component: () => import("@/views/services/ShowService.vue")
  },
  {
    path: "/terms-condition",
    name: "terms-condition",
    meta: {
      layout: 'blank',
    },
    component: () => import("@/views/TermsCondition.vue")
  },
  {
    path: "/order",
    name: "order",
    meta: {
      requiresAuth: true
    },
    component: () => import("@/views/Order.vue")
  },
  {
    path: "*",
    name: "error-404",
    component: () => import("@/views/404.vue"),
    meta: {
      layout: "blank"
    }
  },
  {
    path: "*",
    redirect: "error-404"
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  const isLoggedIn =
    store.state.login.accessToken ?? localStorage.getItem("accessToken");
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const requiresVisitor = to.matched.some(
    record => record.meta.requiresVisitor
  );

  if (requiresAuth) {
    if (!isLoggedIn) {
      next({ name: "login" });
    } else {
      next();
    }
  } else if (requiresVisitor) {
    if (isLoggedIn) {
      next({ name: "order" });
    }
    next();
  } else {
    next();
  }
});

export default router;

// this.$cookies.this.$refs['trjim.com']
